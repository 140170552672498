<template>
  <div>
    <Planification
      :matter_id="matter_id"
      :allows_crud="allows_crud"
    ></Planification>

    <EvaluationCustomFab
      class="noprint"
      :matter_id="matter_id"
      :show_plannification="false"
    ></EvaluationCustomFab>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "PlanificationView",
  components: {
    Planification: () => import("./Planification"),
    EvaluationCustomFab: () =>
      import("@/components/evaluations/EvaluationCustomFab"),
  },
  data() {
    return {
      matter_id: Number(this.$route.params.matter_id),
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      egressProfiles: names.EGRESS_PROFILES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "teaching.change_section teaching.add_evaluation"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    matter() {
      return this.egress_profiles_matters.find((x) => x.id == this.matter_id);
    },
    egressProfile() {
      if (!this.matter) return [];
      return this.egressProfiles.find(
        (x) => x.id == this.matter.egress_profile
      );
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE_MATTER, this.matter_id)
      .then((response) => {
        this.$store.dispatch(names.FETCH_EP_MATTER_TIME_ALLOCATIONS, {
          egress_profile_matter_id: response.id,
        });
        this.$store.dispatch(
          names.FETCH_EGRESS_PROFILE,
          response.egress_profile
        );
        this.$store.dispatch(names.FETCH_MATTER, response.matter);
        this.$store.dispatch(names.FETCH_STUDY_UNITS, response.matter);
      });
  },
  mounted() {},
};
</script>

<style scoped>
table {
  position: relative;
  width: 100%;
}
th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color);
  color: white;
  padding: 0px 5px;
  position: sticky;
  font-size: var(--secondary-font-size);
}
p {
  text-align: justify;
  margin: 0px 5px;
}
</style>